<template>
	<a-card title="" :bordered="false">
		<a-form-model class="form-model" layout="vertical" ref="form" :model="formField" :rules="formRules">
			<a-form-model-item label="姓名：">
				<div class="auth-btn" v-if="formField.authStatus == 0" @click="toAuth">立即认证</div>
				<div class="auth-username" v-else>
					<span class="auth-username__name">{{formField.authStatus == 1 ? formField.nickname : formField.username}}</span>
					<a-tag color="orange" v-if="formField.authStatus == 2">审核中</a-tag>
					<a-tooltip placement="right" v-if="formField.authStatus == 3">
						<div slot="title" class="auth-username__tooltip">
							<p>{{formField.authReason}}</p>
							<a-button type="primary" @click="toAuth">前往修改</a-button>
						</div>
						<a-tag color="#f50">审核不通过&emsp;<a-icon type="question-circle" /></a-tag>
					</a-tooltip>
				</div>
			</a-form-model-item>
			<a-form-model-item label="信誉分：">
				<div class="credit-tip">
					<span class="credit-tip__num">{{formField.credit}}</span>
					<a-tooltip placement="right">
						<div slot="title" class="credit-tip__tooltip">
							<p>每在截止时间前3天提交稿件，加1分</p>
							<p>截止日期3天内提交不加分</p>
							<p>超过截止日期，未提交，扣5分</p>
						</div>
						<a-tag v-if="formField.credit >= 100" color="#27B148">优秀&emsp;<a-icon type="question-circle" /></a-tag>
						<a-tag v-else-if="formField.credit >= 80" color="#FC9400">良好&emsp;<a-icon type="question-circle" /></a-tag>
						<a-tag v-else color="#FF2525">差&emsp;<a-icon type="question-circle" /></a-tag>
					</a-tooltip>
				</div>
			</a-form-model-item>
			<a-form-model-item label="笔名：" prop="penname">
				<a-input v-model="formField.penname" size="large" placeholder="请输入笔名" />
			</a-form-model-item>
			<a-form-model-item label="QQ号码：" prop="qq">
				<a-input v-model="formField.qq" size="large" placeholder="请输入QQ号码" />
			</a-form-model-item>
			<a-form-model-item label="微信号：" prop="wechat">
				<a-input v-model="formField.wechat" size="large" placeholder="请输入微信号码" />
			</a-form-model-item>
			<a-form-model-item label="邮箱：" prop="email">
				<a-input v-model="formField.email" size="large" placeholder="请输入邮箱地址" />
			</a-form-model-item>
			<a-form-model-item label=" " :colon="false">
				<a-button size="large" type="primary" :loading="loadBtn" :disabled="loadBtn" @click="formSubmit">
					保存
				</a-button>
			</a-form-model-item>
		</a-form-model>
	</a-card>
</template>

<script>
import { mapState } from "vuex";
export default {
	data() {
		return {
			formRules: {
				penname: [
					{required: true, message: '请输入笔名', trigger: 'blur'}
				],
                qq: [
                    {  pattern: /^[1-9][0-9]{4,14}$/, message: '请输入正确的QQ号', trigger: 'blur'}
                ],
				email: [
                    {  pattern: /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/, message: '请输入正确的邮箱地址', trigger: 'blur'}
                ],
            },
			formField: {
				authStatus: '', //认证状态
				authReason: '', //认证失败原因
				username: '',
				nickname: '',
				credit: '80', //信誉分
				qq: '',
				wechat: '',
				email: '',
				penname: ''
			},
			loadBtn: false
		}
	},
	computed: {
		...mapState({
			userInfo: state => state.user.user_info
		}),
	},
	watch: {
		userInfo() {
			this.updateUserinfo();
		}
	},
	mounted() {
		this.updateUserinfo();
	},
	methods: {
		// 更新用户信息
		updateUserinfo() {
			let userInfo = this.$store.state.user.user_info
			Object.assign(this.formField, {
				username: (userInfo.auth_info instanceof Object) ? userInfo.auth_info.username : '',
				nickname: userInfo.nickname,
				authReason: userInfo.auth_reason,
				credit: userInfo.credit,
				authStatus: userInfo.auth_status,
				qq: userInfo.qq,
				wechat: userInfo.wechat,
				email: userInfo.email,
				penname: userInfo.penname,
			})
		},
		// 表单提交
		formSubmit() {
			this.$refs.form.validate(ispass => {
				if(ispass) {
					this.loadBtn = true;
					let formData = Object.assign({}, this.formField);
					delete formData.authReason;
					delete formData.authStatus;
					delete formData.credit;
					delete formData.username;
					delete formData.nickname;
					this.$store.dispatch('user/updataInfo', formData).then(res => {
						this.$message.success({ content: '更新成功', key: 'message' });
					}).catch((err) => {
						// 更新失败
						this.$message.error({ content: err.msg, key: 'message' });
                    }).finally(() => {
                        this.loadBtn = false;
                    })
				}
      		});
		},
		// 认证表单
		toAuth() {
			this.$router.push({
                name: 'myAuth'
            }).catch(err => {console.warn(err)})
		}
	}
};
</script>

<style lang="less" scoped>
.auth-btn{
	font-size: 16px;
	color: @color-default;
	text-decoration: underline;
	cursor: pointer;
}
.auth-username {
	display: flex;
	align-items: center;
	&__name{
		font-size: 30px;
		margin-right: 10px;
	}
	&__tooltip{
		padding: 10px 20px;
	}
}
// 信誉分
.credit-tip{
	display: flex;
	align-items: center;
	&__num{
		font-size: 32px;
		margin-right: 10px;
	}
	&__tooltip{
		width: 320px;
		p{
			margin-bottom: 5px;
		}
	}
	
}
// 表单
.form-model{
	/deep/ .ant-form-item-label label{
		font-size: 16px;
	}
}
</style>
